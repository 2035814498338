import React from 'react';
import Logo from 'Images/logo-current-color.turbopack-temp-svg';

type HeaderLogoProps = {
  color?: 'orange' | 'black' | 'white';
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({
  color = 'black'
}) => {
  const logoThemeColor = (() => {
    if (color === 'orange') {
      return 'text-orange-500';
    } else if (color === 'white') {
      return 'text-white';
    }
    return 'text-black';
  })();

  return (
    <div
      data-testid="headerLogo"
      className={`mt-2 md:mt-4 ${logoThemeColor} w-[6rem] md:w-[8rem] lg:w-[10rem] h-[1.5rem] md:h-[2.25rem] lg:h-[3rem]`}
    >
      <Logo />
    </div>
  );
};

export default HeaderLogo;

