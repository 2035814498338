
export enum AppRoutePath {
  // TODO: delete if unused
  SIGNUP = '/signup',
  REGISTER = '/register',

  // Customer
  CUSTOMER_SIGNUP = '/customer-signup',
  CUSTOMER_ACCOUNT_SETTINGS = '/customer/account/settings',
  CUSTOMER_ACCOUNT_PASSWORD = '/customer/account/password',
  CUSTOMER_ACCOUNT_PAYMENT = '/customer/account/payment',
  CUSTOMER_ACCOUNT_ORDER_HISTORY = '/customer/account/order-history',
  CUSTOMER_ACCOUNT_LOCATIONS = '/customer/account/locations',
  CUSTOMER_ACCOUNT_SUPPORT = '/customer/account/support',
  CUSTOMER_COMPLETED_BOOKING_DETAILS = '/customer/completed-booking/[bookingUid]',
  CUSTOMER_BOOKING_DETAILS = '/customer/booking/[bookingUid]',
  HOME = '/home',
  BOOKING_CONFIRMATION = '/booking-confirmation/[appointmentUid]',
  BROWSE_SERVICE_TYPES = '/browse',
  BROWSE_SERVICE_PROVIDERS = '/providers/[serviceType]',
  SERVICE_PROVIDER = '/provider/[serviceProviderOwnerUid]',
  API_SUBMIT_APPOINTMENT_CART_AND_BOOKING_CART = '/api/submit-appointment-cart-and-booking-cart/[locationUid]/[appointmentCartUid]/[bookingCartUid]',
  API_SUBMIT_BOOKING_CART = '/api/submit-booking-cart/[bookingCartUid]',
  API_ENSURE_LOCATION_SUBSCRIPTION = '/api/ensure-location-subscription/[locationUid]',
  LINK_PAYMENT_METHOD = '/link-payment-method',
  API_BOOK_FOLLOW_ON = '/api/book-follow-on/[serviceProviderUid]/[lat]/[lng]/[dateTimeUnix]',
  API_RECOVER_CART = '/api/recover-cart/[bookingCartUid]',
  UPDATE_USERNAME = '/update-username',
  SET_REVIEW = '/set-review/[bookingUid]',
  LOCATION_EVENTS = '/events/[businessSlug]/[locationSlug]',
  EVENTS_HOW_IT_WORKS = '/events/[businessSlug]/[locationSlug]/how-it-works',
  EVENTS_PRINT_PORTRAIT = '/events/[businessSlug]/[locationSlug]/print/portrait',
  EVENTS_PRINT_LANDSCAPE = '/events/[businessSlug]/[locationSlug]/print/landscape',
  APPOINTMENT_REQUEST_V3_CUSTOMER_INPUTS = '/request-appointment/[serviceProviderId]/[serviceOfferingUid]/customer-inputs',
  APPOINTMENT_REQUEST_V3_SELECT_START_DATE_TIME = '/request-appointment/[serviceProviderId]/[serviceOfferingUid]/start-date-time',
  APPOINTMENT_REQUEST_V3_SUMMARY = '/request-appointment/[serviceProviderId]/summary',
  APPOINTMENT_REQUEST_V3_CONFIRMATION = '/request-appointment/confirmation/[appointmentId]',
  APPOINTMENT_REQUEST_V3_PENDING_LOCATION_APPROVAL = '/request-appointment/pending-location-approval/[appointmentRequestUid]',
  LEGACY_SP = '/sp/[serviceProviderSlug]',
  SP = '/service-provider/[serviceProviderSlug]',
  LOCAL_SP = '/local-service-provider/[serviceProviderSlug]',
  BOOK_V3_SERVICE_OFFERING = '/book/[appointmentId]/service-offering',
  BOOK_V3_CUSTOMER_INPUTS = '/book/[appointmentId]/customer-inputs',
  BOOK_V3_START_TIME = '/book/[appointmentId]/start-time',
  BOOK_V3_SUMMARY = '/book/[appointmentId]/summary',
  API_BOOK_SUBMIT = '/api/book/[appointmentId]/[bookingCartUid]/submit',
  LOCAL_PROVIDERS = '/local-providers/[city]/[serviceType]',
  API_ACCEPT_CUSTOMER_INVITE = '/api/accept-customer-invite/[customerInviteId]',

  // Business
  BUSINESS_BROWSE_SERVICE_TYPES = '/business/browse',
  BUSINESS_SIGNUP = '/business-signup',
  BUSINESS_MEMBERS = '/business/members',
  BUSINESS_SCHEDULE = '/business',
  BUSINESS_APPOINTMENT_DETAILS = '/business/appointment/[appointmentId]',
  BUSINESS_ACTIVITY_DASHBOARD = '/business/activity-dashboard',
  BUSINESS_ACCOUNT_PROFILE = '/business/profile',
  BUSINESS_ACCOUNT_EDIT_PROFILE = '/business/edit-profile',
  BUSINESS_BROWSE_SERVICE_TYPE = '/business/browse/[serviceType]',
  BUSINESS_PROVIDER_DETAILS = '/business/sp/[serviceProviderSlug]',
  BUSINESS_BOOK_EVENT_SELECT_SERVICE_OFFERINGS = '/business/book-event/[serviceProviderId]/select-service-offerings',
  BUSINESS_BOOK_EVENT_SELECT_START_DATE_TIME = '/business/book-event/[serviceProviderId]/[eventOfferingId]/select-start-date-time',
  BUSINESS_BOOK_EVENT_MISC_DETAILS = '/business/book-event/[serviceProviderId]/[eventOfferingId]/misc-details',
  BUSINESS_BOOK_EVENT_SUMMARY = '/business/book-event/[serviceProviderId]/[eventOfferingId]/summary',
  BUSINESS_BOOK_EVENT_CONFIRMATION = '/business/confirmation/[appointmentId]',
  API_INVITE = '/api/invite/[appointmentId]',
  BUSINESS_LOCATIONS = '/business/locations',
  BUSINESS_NEW_LOCATION = '/business/new-location',
  BUSINESS_EDIT_LOCATION = '/business/edit-location/[locationUid]',
  BUSINESS_ACCOUNT_BILLING = '/business/billing',
  BUSINESS_ACCOUNT_ADD_PAYMENT_METHOD = '/business/add-payment-method',
  BUSINESS_ACCOUNT_SUPPORT = '/business/support',

  // Service provider
  SERVICE_PROVIDER_SIGNUP = '/service-provider-signup',
  SERVICE_PROVIDER_DASHBOARD = '/sp-dashboard',
  SERVICE_PROVIDER_DASHBOARD_SCHEDULE = '/sp-dashboard/schedule',
  SERVICE_PROVIDER_DASHBOARD_ORDER_HISTORY = '/sp-dashboard/order-history',
  SERVICE_PROVIDER_DASHBOARD_DISCOUNT_RULES = '/sp-dashboard/discount-rules',
  SERVICE_PROVIDER_DASHBOARD_SERVICES = '/sp-dashboard/services',
  SERVICE_PROVIDER_DASHBOARD_SETTINGS = '/sp-dashboard/settings',
  SERVICE_PROVIDER_DASHBOARD_SETUP_STRIPE = '/sp-dashboard/setup-stripe',
  EDIT_BOOKING = '/sp-dashboard/edit-booking/[bookingUid]',
  SERVICE_PROVIDER_DASHBOARD_APPOINTMENT = '/sp-dashboard/appointment/[appointmentUid]',
  SERVICE_PROVIDER_DASHBOARD_SETTINGS_GOOGLE_CALENDAR = '/sp-dashboard/settings/google-calendar',
  SERVICE_PROVIDER_DASHBOARD_SETTINGS_EVENT_OFFERINGS = '/sp-dashboard/settings/event-offerings',
  SERVICE_PROVIDER_DASHBOARD_SETTINGS_SQUARE = '/sp-dashboard/settings/square',
  SERVICE_PROVIDER_DASHBOARD_ONBOARDING = '/sp-dashboard/onboarding/[stageSlug]',
  SERVICE_PROVIDER_DASHBOARD_CUSTOMERS = '/sp-dashboard/customers',
  SERVICE_PROVIDER_DASHBOARD_CUSTOMER_DETAILS = '/sp-dashboard/customers/[customerId]',
  SERVICE_PROVIDER_DASHBOARD_CUSTOMER_PROFILE_FIELDS = '/sp-dashboard/customer-profile-fields',
  SERVICE_PROVIDER_DASHBOARD_EMAILS_TEMPLATES = '/sp-dashboard/emails/templates',
  SERVICE_PROVIDER_DASHBOARD_EMAILS_RECEIPT_CONFIG = '/sp-dashboard/emails/receipt-config',
  SERVICE_PROVIDER_DASHBOARD_SETTINGS_LOCATIONS = '/sp-dashboard/settings/locations',
  SERVICE_PROVIDER_DASHBOARD_SETTINGS_PLAN = '/service-provider-plans',
  SERVICE_PROVIDER_DASHBOARD_PENDING_APPOINTMENTS = '/sp-dashboard/pending-appointments',
  SERVICE_PROVIDER_DASHBOARD_COMPLETABLE_APPOINTMENTS = '/sp-dashboard/completable-appointments',
  SERVICE_PROVIDER_DASHBOARD_APPSTORE_MANAGE_PLUGIN = '/sp-dashboard/appstore/plugin/manage/[pluginInstallationId]',
  SERVICE_PROVIDER_DASHBOARD_APPSTORE_INSTALL_PLUGIN = '/sp-dashboard/appstore/plugin/install/[pluginListingId]',

  // General
  LOGIN = '/login',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  API_LOG_OUT = '/api/log-out',
  API_LOGIN = '/api/login',

  // Legacy
  DASHBOARD = '/dashboard',
  SIGNUP_COMPLETE = '/signup-complete',
  PARTNER = '/partner',
  BOOK = '/book/[eventUid]/[stage]/[widgetStage]',
  ANONYMOUS_SIGNIN = '/anonymous-signin',
  // Deprecated, redirects to book v3
  API_BOOK_NOW = '/api/book-now/[appointmentId]',

  // Uncategorized
  MANAGER_DASHBOARD = '/manager-dashboard',
}

export enum RedirectState {
  NOT_FOUND = '404',
  NO_LOCATION_SUBSCRIPTIONS = 'nls',
}

export enum AppRouteSearch {
  View = 'view',
}

export type AppRoute = {
  route: AppRoutePath[keyof AppRoutePath];
  public?: boolean;
  header?: boolean;
  hideDashboardButton?: boolean;
  headerActions?: boolean;
}
