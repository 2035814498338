'use client';
import React from 'react';
import { AppRoutePath } from 'Pages/routes';
import { getCurrentRoute } from 'Common/functions/Route';
import { usePathname, useRouter } from 'next/navigation';
import Link from 'next/link';
import NavItem from 'Common/components/Navigation/NavItem';

type NavLinkProps = {
  href?: AppRoutePath | string;
  getHref?: () => Promise<string>;
  children: React.ReactNode;
  linkVariant?: 'nav.primary' | 'nav.secondary' | 'default';
  testId?: string;
};

const NavLink: React.FC<NavLinkProps> = ({
  href,
  getHref,
  children,
  linkVariant,
  testId,
}) => {
  const pathname = usePathname();
  const currentRoute = getCurrentRoute(pathname);
  const isActiveRoute = currentRoute ? href === currentRoute.route : false;
  const linkClasses = isActiveRoute ?
    'font-bold text-primary' :
    'font-normal text-secondary';

  const router = useRouter();

  const content = (
    <span
      data-testid={testId}
      className={`no-underline ${linkClasses}`}
    >
      <NavItem
        variant={linkVariant}
        isActiveRoute={isActiveRoute}
      >
        {children}
      </NavItem>
    </span>
  );

  return href ? (
    <Link href={href}>
      {content}
    </Link>
  ) : (
    <div onClick={() => getHref && getHref().then(href => router.push(href))}>
      {content}
    </div>
  );
};

export default NavLink;

