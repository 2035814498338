'use client';
import React from 'react';

type NavItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
  isActiveRoute?: boolean;
  variant?: 'nav.primary' | 'nav.secondary' | 'default';
  testId?: string;
};

const NavItem: React.FC<NavItemProps> = ({
  children,
  onClick,
  isActiveRoute,
  variant = 'default', // Assuming default style variant if not provided
  testId,
}) => {
  // Define base styles for NavItem based on variant
  let baseClasses = 'cursor-pointer uppercase text-white font-bold sm:text-2xl md:text-lg hover:text-gray-200 select-none ';
  let activeClasses = ' ';

  // Apply variant-specific styles
  switch (variant) {
    case 'nav.primary':
      activeClasses = 'font-bold ';
      break;
    case 'nav.secondary':
      activeClasses = 'underline ';
      break;
    default:
      break;
  }

  // Apply active-specific styles
  if (isActiveRoute) {
    baseClasses += activeClasses;
  }

  return (
    <span
      data-testid={testId}
      onClick={onClick}
      className={baseClasses.trim()}
    >
      {children}
    </span>
  );
};

export default NavItem;

