import React from 'react';
import { AppRoutePath } from 'Pages/routes';
import { NavItem } from '../NavigationItems/items';
import NavigationLayout from '../NavigationLayout';
import BookAServiceButton from '../BookAServiceButton';

type CustomerNavigationProps = {
  children?: React.ReactNode;
  items?: NavItem[];
  suffix?: React.ReactNode;
  onClickBookAService?: () => void;
};

const CustomerNavigation: React.FC<CustomerNavigationProps> = ({
  children,
  items,
  suffix,
  onClickBookAService,
}) => {
  return (
    <NavigationLayout
      items={items}
      logoHref={AppRoutePath.BROWSE_SERVICE_TYPES}
      suffix={(
        <>
          {suffix}
          <BookAServiceButton onClick={onClickBookAService} />
        </>
      )}
    >
      {children}
    </NavigationLayout>
  );
};

export default CustomerNavigation;

