import { AppRoutePath } from 'Pages/routes';
import Calendar from 'Images/svg/calendar.svg';
import Link from 'next/link';

type BookAServiceButtonProps = {
  url?: string;
  onClick?: () => void;
}

const BookAServiceButton: React.FC<BookAServiceButtonProps> = ({
  url,
  onClick,
}) => {
  const content = (
    <button
      className="bg-black hover:bg-gray-900 text-white font-bold py-2 sm:px-2 md:px-4 rounded flex gap-2 sm:text-xs md:text-base"
      onClick={onClick}
    >
      <Calendar />
      Book a Service
    </button>
  );

  return onClick ? content : (
    <Link href={url || AppRoutePath.BROWSE_SERVICE_TYPES}>
      {content}
    </Link>
  );
};

export default BookAServiceButton;
