export enum SearchParam {
  VIEW = 'view',
  IS_DEBUG_MODE = 'debug',
  BOOKING_PAGE_MASQUERADE_AS = 'bpma',
  BOOKING_PAGE_DEFAULT_STAGE = 'bpds',
  CONFIRMATION_PAGE_EVENT_UID = 'cpeu',
  CONFIRMATION_PAGE_SERVICE_PROVIDER_UID = 'cpspu',
  CONFIRMATION_PAGE_LOCATION_UID = 'cplu',
  _SKIP_AUTH = '_skipAuth',
  RETURN_TO = 'returnTo',
  ENSURE_LOCATION_SUBSCRIPTION_RETURN_TO = 'elsReturnTo',
  SKIPPED_INDEX = 'si',
  FB_ACTION_CODE = 'oobCode',
  FB_CONTINUE_URL = 'continueUrl',
  FB_MODE = 'mode',
  BOOKING_WIDGET_FORCE_EDIT = 'bwfe',
  BROWSE_SERVICE_PROVIDERS_LAT = 'bspla',
  BROWSE_SERVICE_PROVIDERS_LNG = 'bspln',
  UNAUTH_REDIRECT_TO = 'uart',
  POST_SIGNUP_MIGRATE_ANONYMOUS_ACCOUNT_ID_TOKEN = 'psmaait',
  TIMEZONE = 'tz',
  BROWSE_PAGE_DEFAULT_LOCATION_UID = 'bpdlu',
  BROWSE_PAGE_POLL_FOR_LOCATION_SUBSCRIPTION = 'bppfls',
  PAGE = 'page',
  PAGE_SIZE = 'pageSize',
  FILTER = 'filter',
  APPOINTMENT_REQUEST_RECOMMENDED_START_DATE_TIME = 'arrsdt',
  APPOINTMENT_REQUEST_CUSTOMER_SET_SELECTED_LOCATION_ID_COOKIE = 'arcsslic',
  LOGIN_FORCE_EMAIL = 'lfe',
}
