import { AccountType } from './AccountType';
import { AppCookie, setCookie } from './Cookie';
import { AppRoutePath } from 'Pages/routes';

type signinResponse = {
  fullName: string;
  accountType: AccountType;
  isNewUser: boolean;
  country: string;
  ownerUid: string;
}

const signinWithIdToken = async (
  idToken: string,
  createIfNotExists: boolean,
  onSuccess?: (res: signinResponse) => void,
  onError?: () => void,
) => {
  const response = await fetch(AppRoutePath.API_LOGIN, {
    method: 'POST',
    body: JSON.stringify({
      createIfNotExists,
    }),
    headers: {
      Authorization: idToken,
      TimezoneLocation: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  if (response.status === 200) {
    const resData = await response.json();
    setCookie(AppCookie.PeazyIsLoggedIn, 'true', 3600 * 24 * 14);
    onSuccess && onSuccess({
      fullName: resData.user.fullName,
      accountType: resData.user.accountType,
      isNewUser: resData.isNewUser,
      country: resData.country,
      ownerUid: resData.ownerUid,
    });
  } else {
    onError && onError();
  }
};

export default signinWithIdToken;
