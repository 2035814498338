type CenteredSpinnerProps = {
  size?: 'small' | 'base' | 'large',
  testid?: string;
  invisible?: boolean;
  classes?: string;
}

const CenteredSpinner: React.FC<CenteredSpinnerProps> = ({
  testid,
  size,
  invisible = false,
  classes = '',
}) => {
  const sizeCls = size === 'small' ? 'w-4 h-4' : 'w-6 h-6';
  const invisCls = invisible ? 'opacity-0' : 'animate-spin';

  return (
    <div
      className="flex justify-center"
      data-testid={testid}
    >
      <div
        className={`border-4 border-t-4 border-transparent border-t-white rounded-full ${sizeCls} ${invisCls} ${classes}`}
      />
    </div>
  );
};

export default CenteredSpinner;
