'use client';
import React from 'react';
import { AppRoutePath } from 'Pages/routes';
import { getAuth } from 'firebase/auth';
import 'Clients/FireBaseClient';

const LogOut = () => {
  const onClickSignout = () => {
    getAuth().signOut().finally(() => {
      window.location.href = AppRoutePath.API_LOG_OUT;
    });
  };

  return (
    <span
      onClick={onClickSignout}
      className="cursor-pointer uppercase text-white font-bold sm:text-2xl md:text-lg hover:text-gray-500 select-none"
    >
      Log Out
    </span>
  );
};

export default LogOut;

