'use client';
import { useState } from 'react';
import MenuClose from 'Images/svg/menu-close.turbopack-temp-svg';
import MenuOpen from 'Images/svg/menu-open.turbopack-temp-svg';

type CustomerNavigationSlideUpProps = {
  slideup: React.ReactNode;
}

const CustomerNavigationSlideUp: React.FC<CustomerNavigationSlideUpProps> = ({
  slideup,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const currentIcon = isOpen ? (
    <MenuClose className="fill-white stroke-white stroke-[2.2px]" onClick={(): void => setIsOpen(false)} />
  ) : (
    <MenuOpen className="fill-white stroke-white stroke-[2.2px]" onClick={(): void => setIsOpen(true)} />
  );

  return (
    <>
      <div className="cursor-pointer absolute right-4 pt-1.5 md:hidden">
        {currentIcon}
      </div>
      {isOpen && slideup}
    </>
  );
};

export default CustomerNavigationSlideUp;
