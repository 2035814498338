import React from 'react';
import HeaderLogo from 'Common/components/server/HeaderLogo';
import NavigationSlideUp from 'Common/components/NavigationSlideUp';
import CustomerNavigationSlideUp from 'Common/components/CustomerNavigationSlideUp';
import { NavItem } from '../NavigationItems/items';
import CustomerNavigationSlideupItems from '../CustomerNavigationSlideupItems';
import User from 'Images/svg/user.svg';
import NavLink from 'Common/tw-components/NavLink';
import ChevronDown from 'Images/svg/chevron-down.svg';

type NavigationLayoutProps = {
  items: NavItem[] | undefined;
  children?: React.ReactNode;
  suffix?: React.ReactNode;
  logoHref: string;
};

const navItemClasses = 'text-white hover:text-gray-300 font-bold text-base uppercase tracking-wide cursor-pointer';

const NavigationLayout: React.FC<NavigationLayoutProps> = ({
  children,
  items = [],
  suffix = null,
  logoHref,
}) => {
  const content = items.map((item, i) => {
    if (item.href) {
      return (
        <NavLink key={i} href={item.href}>
          {item.content}
        </NavLink>
      );
    } else if (item.getHref) {
      return (
        <NavLink key={i} getHref={item.getHref}>
          {item.content}
        </NavLink>
      );
    }

    return <div key={i}>{item.content}</div>;
  });

  return (
    <>
      <div className="sm:min-h-[60px] lg:min-h-[88px] max-h-[100px] w-full" />
      <header className="fixed top-0 z-20 bg-primary py-3 navbar-container sm:min-h-[60px] md:min-h-0 max-h-[100px] w-full">
        <div className="flex justify-between items-center sm:pr-4 md:pr-0">
          <div className="flex items-center sm:gap-4 md:gap-8">
            <a href={logoHref}
              className="cursor-pointer">
              <HeaderLogo color="white" />
            </a>
            <div className="max-w-[60%] md:max-w-[70%]">
              {children}
            </div>
          </div>
          <div className="hidden md:flex flex-basis-55 items-center gap-4">
            {items.length > 0 && (
              <div className="group relative dropdown px-4">
                <span className={`flex gap-2 items-center ${navItemClasses}`}>
                  <User />My Account<ChevronDown />
                </span>
                <div className="group-hover:block dropdown-menu absolute hidden h-auto">
                  <div className="flex flex-col gap-2 top-0 w-56 shadow px-6 pt-4 pb-8 bg-primary rounded mt-4">
                    {content}
                  </div>
                </div>
              </div>
            )}
            {suffix}
          </div>
          <CustomerNavigationSlideUp
            slideup={(
              <NavigationSlideUp isOpen={true}>
                <CustomerNavigationSlideupItems items={items} />
                {suffix && (
                  <div className="mt-4">
                    {suffix}
                  </div>
                )}
              </NavigationSlideUp>
            )}
          />
        </div>
      </header>
    </>
  );
};

export default NavigationLayout;

