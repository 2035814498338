import { Auth, signInAnonymously } from 'firebase/auth';
import signinWithIdToken from './signinWithIdToken';

const appSignInAnonymously = async (
  auth: Auth,
): Promise<void> => {
  const r = await signInAnonymously(auth);
  return new Promise(resolve => {
    r.user.getIdToken().then((idToken) => {
      signinWithIdToken(idToken, true, () => {
        resolve();
      });
    });
  });
};

export default appSignInAnonymously;

