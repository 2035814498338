import NavLink from 'Common/tw-components/NavLink';
import { NavItem } from '../NavigationItems/items';

type CustomerNavigationSlideupItemsProps = {
  items: NavItem[];
}

const CustomerNavigationSlideupItems: React.FC<CustomerNavigationSlideupItemsProps> = ({
  items,
}) => {
  const content = items.map((item, i) => {
    if (item.href) {
      return <NavLink key={i} href={item.href}>
        {item.content}
      </NavLink>;
    } else if (item.getHref) {
      return <NavLink key={i} getHref={item.getHref}>
        {item.content}
      </NavLink>;
    }

    return <div key={i}>{item.content}</div>;
  });

  return (
    <div className="flex md:flex-row items-start justify-between md:justify-end space-x-0 md:space-x-4">
      <nav className="flex flex-col justify-start md:justify-end items-start md:items-center space-x-0 md:space-x-4 sm:gap-4">
        {content}
      </nav>
    </div>
  );
};

export default CustomerNavigationSlideupItems;

