'use client';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';

type NavigationSlideUpProps = {
  isOpen: boolean;
  children?: React.ReactNode;
}

const NavigationSlideUp: React.FC<NavigationSlideUpProps> = ({
  isOpen,
  children,
}) => {
  const navRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = navRef.current;
    if (currentRef) {
      if (isOpen) {
        disableBodyScroll(currentRef);
      } else {
        enableBodyScroll(currentRef);
      }
    }
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  return isOpen ? (
    <div
      className="fixed top-[60px] z-20 w-full left-0"
      ref={navRef}
      style={{ display: isOpen ? 'block' : 'none' }}
    >
      <motion.div
        animate={{ y: 0 }}
        initial={{ y: '100%' }}
        exit={{ y: '100%' }}
        transition={{ duration: 0.3, type: 'ease-in' }}
        className="absolute w-full"
      >
        <div className="bg-primary shadow-none rounded-none h-screen pl-4">
          {children}
        </div>
      </motion.div>
    </div>
  ) : null;
};

export default NavigationSlideUp;

