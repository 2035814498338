import { initializeApp } from 'firebase/app';
import {
  getAuth,
  connectAuthEmulator,
  User,
  Auth,
} from 'firebase/auth';
import { getAnalytics, isSupported } from 'firebase/analytics';
import fbc from 'firebase/compat/app';
import 'firebase/compat/auth';

const fbApiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY ||
  process.env.FIREBASE_API_KEY;
const fbAuthDomain = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ||
  process.env.FIREBASE_AUTH_DOMAIN;
const fbDbUrl = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL ||
  process.env.FIREBASE_DATABASE_URL;
const fbProjectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ||
  process.env.FIREBASE_PROJECT_ID;
const fbStorageBucket = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ||
  process.env.FIREBASE_STORAGE_BUCKET;
const fbMessageSender = process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID ||
  process.env.FIREBASE_MESSAGE_SENDER_ID;
const fbAppId = process.env.NEXT_PUBLIC_FIREBASE_APP_ID ||
  process.env.FIREBASE_APP_ID;
const fbMid = process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ||
  process.env.FIREBASE_MEASUREMENT_ID;

export const FirebaseConfig = {
  apiKey: fbApiKey as string,
  authDomain: fbAuthDomain,
  databaseURL: fbDbUrl,
  projectId: fbProjectId,
  storageBucket: fbStorageBucket,
  messagingSenderId: fbMessageSender,
  appId: fbAppId,
  measurementId: fbMid,
};

const isDev = process.env.NEXT_PUBLIC_NODE_ENV !== 'production';
let fbAuth: Auth | null = null;

fbc.auth(fbc.initializeApp(FirebaseConfig));

const fb = initializeApp(FirebaseConfig);
fbAuth = getAuth(fb);

isSupported().then(s => s && getAnalytics(fb));

if (isDev) {
  const emulatorHost = process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_URL;
  if (emulatorHost) {
    connectAuthEmulator(fbAuth, `http://${emulatorHost}/`);
    fbc.auth().useEmulator(`http://${emulatorHost}/`);
  }
}

export default class FirebaseClient {
  static getCurrentUser(): User | null {
    return fbAuth ? fbAuth.currentUser : null;
  }
}
