import { AppRoute, AppRoutePath } from './routes';

const pages: AppRoute[] = [
  {
    route: AppRoutePath.DASHBOARD,
    header: false
  },
  {
    route: AppRoutePath.PARTNER,
    public: true,
    hideDashboardButton: true
  },
  {
    route: AppRoutePath.HOME,
    header: true,
    public: false,
  },
  {
    route: AppRoutePath.BOOKING_CONFIRMATION,
    header: true,
    public: false,
  },
  {
    route: AppRoutePath.BROWSE_SERVICE_PROVIDERS,
    public: false,
  },
  {
    route: AppRoutePath.SERVICE_PROVIDER,
    public: false,
  },
  {
    route: AppRoutePath.SIGNUP,
    public: true,
  },
  {
    route: AppRoutePath.LOGIN,
    public: true,
  },
  {
    route: AppRoutePath.BROWSE_SERVICE_TYPES,
    public: true,
  },
  {
    route: AppRoutePath.REGISTER,
    public: true
  },
  {
    route: AppRoutePath.RESET_PASSWORD,
    public: true
  },
  {
    route: AppRoutePath.FORGOT_PASSWORD,
    public: true
  },
];

export default pages;

