'use client';

import { getAuth } from 'firebase/auth';
import CustomerNavigation from '../server/CustomerNavigation';
import {
  getAnonymousUserIdToken,
  getLoginAndMergeUrl,
  getLoginOrLoginAndMergeUrl
} from 'Common/functions/UpgradeAnonymousAccount';
import { usePathname } from 'next/navigation';
import 'Clients/FireBaseClient';
import NavLink from 'Common/tw-components/NavLink';

type AnonymousUserNavigationProps = {
  children?: React.ReactNode;
  onClickBookAService?: () => void;
}

const AnonymousUserNavigation: React.FC<AnonymousUserNavigationProps> = ({
  children,
  onClickBookAService,
}) => {
  const pathname = usePathname();

  const getLoginUrl = async () => {
    const anonUser = getAuth().currentUser;
    const anonUserIdToken = anonUser?.isAnonymous ?
      await getAnonymousUserIdToken(anonUser) :
      '';
    const loginUrl = getLoginOrLoginAndMergeUrl(
      anonUserIdToken,
      pathname,
      getLoginAndMergeUrl,
    );

    return loginUrl;
  };

  return (
    <CustomerNavigation
      onClickBookAService={onClickBookAService}
      suffix={(
        <NavLink getHref={getLoginUrl}>
          Login
        </NavLink>
      )}
    >
      {children}
    </CustomerNavigation>
  );
};

export default AnonymousUserNavigation;
