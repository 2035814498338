import pages from 'Pages/pages';
import { AppRoute } from 'Pages/routes';

export const getCurrentRoute = (
  pathname: string,
): AppRoute | undefined => pages.find(
  route => {
    // cast because route is an enum but will always be a string
    const match = pathname === route.route;

    return Boolean(match);
  }
);

export const redirectIfFalsey = <T extends Record<string, unknown> | Record<string, unknown>[] | string>(
  redir: () => never,
  obj: T | null | undefined,
): T | never => {
  if (!obj) {
    return redir();
  }
  return obj;
};
